import React, { useContext, useEffect, useState } from "react";
import Seo from "../components/Seo/Seo";
import styled from "styled-components";
import { Box } from "../components/Core";
import { device } from "../utils";
import { Container } from "react-bootstrap";
import GlobalContext from "../context/GlobalContext";
import SuccessAppointment from "../sections/Success/SuccessAppointment";
import API from "../api/api";
import { navigate } from "gatsby";
import { Row, Col } from "react-bootstrap";
import { Button, Section } from "../components/Core";
import orderImage from "./../assets/image/svg/register-order.svg"

const AppointmentSuccess = (props) => {

    const gContext = useContext(GlobalContext);

    useEffect(() => {
        const woId = new URLSearchParams(props.location.search).get("woId")
        if (typeof window !== 'undefined' && window) {

            const woConfirmed = JSON.parse(localStorage.getItem(`workOrderConfirmed-${woId}`))

            if (!woConfirmed) {
                API
                    .put(`/FieldServiceModule/v1.0/db/WorkOrder/${woId}`, {
                        entity: "FieldServiceModule:WorkOrder",
                        properties: {
                            ConfirmationStatus: "CONFIRMED"
                        }
                    })
                    .then((resp) => {
                        localStorage.setItem(`workOrderConfirmed-${woId}`, true);
                    })
                    .catch((error) => {
                        gContext.setAPIErrorMsg({
                            title: 'Error',
                            message: error.response ? error.response?.data.message : error
                        })
                    }
                    )
            }
        }
    }, [])

    return (
        <div>
            <Seo
                title={'YouFibre | Appointment Success'}
                description={'YouFibre | Appointment Success'}
            />
            <Section className="green-bg text-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8} md={10} sm={8}>
                            <img src={orderImage} className="mt-30 mb-30" />
                            <h2>Thank you for confirming your install date!</h2>
                            <Button
                                onClick={() => {
                                    navigate('/');
                                }}
                                className="mt-50 light-bg"
                            >Return to homepage</Button>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </div>
    )
}

export default AppointmentSuccess